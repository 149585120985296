import "./App.css";
import { ViewportCenter } from "./components/ViewportCenter";
import { NavComponent } from "./Nav";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <ViewportCenter>
        <NavComponent />
      </ViewportCenter>
    </div>
  );
}

export default App;
