import React from "react";
import "./ParallaxPixelStars.css";

export function ParallaxPixelStars() {
  return (
    <div style={{ position: "fixed", top: 0, height: "100vh" }}>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </div>
  );
}
