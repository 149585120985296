import beeImage from "./res/icons/Bee-min.png";
import squirrelImage from "./res/icons/Squirrel-min.png";
import turtleImage from "./res/icons/Turtle-min.png";
import unicornImage from "./res/icons/Unicorn-min.png";

export const config = {
  squirrel: {
    src: squirrelImage,
    link: "/squirrel",
    enabled: true,
  },
  unicorn: {
    src: unicornImage,
    link: "/unicorn",
    enabled: true,
  },
  bee: {
    src: beeImage,
    link: "/bee",
    enabled: false,
  },
  turtle: {
    src: turtleImage,
    link: "/turtle",
    enabled: false,
  },
};
