import React from "react";

export function ViewportCenter(props) {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "table-cell",
        verticalAlign: "middle",
      }}
    >
      {props.children}
    </div>
  );
}
