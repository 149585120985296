import React from "react";
import {
	Carousel,
	ProgressBar,
	Container,
	Row,
	Col,
	Card
} from "react-bootstrap";
import QRCode from "react-qr-code";
import "./App.css";
import { config } from "./config";
import { getNavReturnElement } from "./Nav";
import { DurationDisplay } from "./DurationDisplay";
import { ParallaxPixelStars } from "./backgrounds/ParallaxPixelStars";
import { ViewportCenter } from "./components/ViewportCenter";
import { storyData } from "./res/bday2023/storyData";

function Unicorn() {
	const empty = "_";
	const gap = "☐";
	const storyItems = [];
	const colCount = 3;
	for (let i = 0; i < storyData.stories.length; i++) {
		let row = Math.floor(i / colCount);
		let col = i % colCount;
		if (col == 0) {
			storyItems.push([storyData.stories[i]]);
		} else {
			storyItems[row].push(storyData.stories[i]);
		}
	}

	return (
		<div className="App" style={{ height: "100vh" }}>
			<ParallaxPixelStars />
			{getNavReturnElement(config.unicorn)}
			<ViewportCenter>
				<Carousel interval={null} indicators={false} controls={true}>
					<Carousel.Item
						className="scrollable"
						style={{
							height: "60vh",
							paddingLeft: "15vw",
							paddingRight: "15vw",
							overflow: "auto"
						}}
					>
						<Container>
							{storyItems.map((storyItemRow, index) => {
								return (
									<Row>
										{storyItemRow.map((storyItemCol, index) => {
											return (
												<Col>
													<Card
														style={{ maxWidth: 300, minWidth: 200, margin: 10 }}
													>
														<Card.Body>
															<Card.Text>{storyItemCol.story}</Card.Text>
															<Card.Footer>{storyItemCol.question}</Card.Footer>
															{storyItemCol.recipient && (
																<Card.Subtitle>
																	{storyItemCol.recipient}
																</Card.Subtitle>
															)}
														</Card.Body>
													</Card>
												</Col>
											);
										})}
									</Row>
								);
							})}
						</Container>
					</Carousel.Item>
					<Carousel.Item
						style={{
							height: "60vh",
							paddingLeft: "15vw",
							paddingRight: "15vw"
						}}
					>
						<Container style={{ background: "#33000000" }}>
							<Row>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{"⠀"}</Col>
							</Row>
							<Row>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{"⠀"}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
							<Row>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{empty}</Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col> </Col>
								<Col>{gap}</Col>
								<Col>{empty}</Col>
								<Col>{empty}</Col>
							</Row>
						</Container>
					</Carousel.Item>
					{/* <Carousel.Item
						style={{
							paddingLeft: "15vw",
							paddingRight: "15vw"
						}}
					>
						<DurationDisplay />
						<br></br>
            <Card style={{marginLeft: "40px", marginRight: "40px"}}>
              <Card.Body>
                <QRCode value={"https://subt.mxdchn.com/"} />
                <Card.Title><br></br>Under Construction</Card.Title>
              </Card.Body>
            </Card>
					</Carousel.Item> */}
					{/* <Carousel.Item>
            <Container>
              <Row>
                <Col>
                  <ProgressBar animated now={45} />
                </Col>
              </Row>
            </Container>
          </Carousel.Item> */}
				</Carousel>
			</ViewportCenter>
		</div>
	);
}

export default Unicorn;
