export const storyData = {
	stories: [
		{
			story:
				"Der Traktor tanzte durch das Feld und spielte dabei auf einem Klavier, das auf seinem Anhänger befestigt war. Die Hühner begannen zu singen und die Wolken tanzten mit, während der Bauer verwirrt zusah und sich fragte, ob er jemals wieder aufhören würde zu träumen.",
			question: "Was will die Erdbeere einmal werden?",
				recipient: undefined
		},
		{
			story:
				"Es war einmal eine Erdbeere, die davon träumte, ein Gnu zu sein. Sie trainierte hart, wurde größer und stärker. Eines Tages wachte sie auf und war tatsächlich ein Gnu. Doch dann bemerkte sie, dass sie sich vor Erdbeeren fürchtete.",
				question: "Mit wem unterhält sich die Nase?",
				recipient: undefined
		},
		{
			story: "Der Priester betete zu seinem Abakus, als plötzlich ein Schaf durch die Kirche galoppierte. Verwirrt stellte er den Abakus zur Seite und fing das Schaf ein. Seitdem betet er nicht mehr zum Abakus, sondern zum Schaf, das ihn vor der Sünde gerettet hat.",
			question: "Welches Instrument spielt der Traktor?",
				recipient: undefined
		},
		{
			story: "Eine Münze rollte in das Ozonloch, wurde von Aliens aufgefangen und in eine intergalaktische Bank gebracht. Dort entdeckte sie eine zufällige Wissenschaftlerin und verwendete sie als Kern für ihre Zeitmaschine. Leider funktionierte sie nicht und die Münze fiel zurück auf die Erde.",
			question: "Was ist in der Flasche für den Frosch?",
				recipient: undefined
		},
		{
			story: "Es war einmal ein Meerrettich, der ein großer Fan des Kolosseums war. Er verkleidete sich als Gladiator und kämpfte gegen Oliven und Tomaten. Eines Tages eroberte er das Kolosseum und wurde zum Kaiser ernannt. Von da an nannte er sich \"Meerrettich Maximus\".",
			question: "Was trägt der Elefant um den Hals?",
				recipient: undefined
		},
		{
			story: "Es war einmal eine Nase, die sich entschied, auf eine abenteuerliche Reise zu gehen. Auf ihrem Weg traf sie eine Ziege, die sie fragte: \"Wohin gehst du?\" Die Nase antwortete: \"Ich suche nach dem Geruch des Regenbogens.\" Die Ziege nickte und sagte: \"Ich verstehe.\"",
			question: "Was verschwindet aus dem Tiergarten?",
				recipient: undefined
		},
		{
			story: "Die Giraffe mit Namen Gertrude liebte es, Karpfen zu fischen. Eines Tages beschloss sie, mit ihrem Angelset den Kilimandscharo zu besteigen. Dort oben fing sie einen Karpfen, der ihr jedoch beim Abstieg entwischte und davonflog. Gertrude war enttäuscht und beschloss, nie wieder zu angeln.",
			question: "Wo ist der Finger?",
				recipient: undefined
		},
		{
			story: "Es war einmal ein Kuchen, der von alleine aus der Bäckerei hüpfte. Er reiste um die Welt und wurde berühmt. Doch eines Tages wurde er von einem Rechtsanwalt verklagt, weil er angeblich das Patent auf Kuchen besaß. Der Kuchen gewann die Klage und lebte glücklich bis ans Ende seiner Tage.",
			question: "Was hat der Priester durch ein Schaf ersetzt?",
				recipient: undefined
		},
		{
			story: "Ein Kopfhörer fiel aus dem Himmel und landete auf dem Keks, der auf dem Boden lag. Der Keks wurde lebendig und tanzte zu den Klängen des Kopfhörers. Niemand glaubte es, bis der Keks auf den Tisch sprang und sang.",
			question: "Was fliegt der Giraffe davon?",
				recipient: undefined
		},
		{
			story: "Der Weihnachtsmann flog in seinem Schlitten, als er plötzlich von einem riesigen Ölfass getroffen wurde. Er stürzte in einen Schornstein und landete in einem Wohnzimmer. Dort entdeckte er einen sprechenden Frosch, der ihm half, Geschenke zu verteilen. Am Ende bekam der Frosch eine Flasche Öl als Dankeschön.",
			question: "Wer verschenkt Blumen?",
				recipient: undefined
		},
		{
			story: "Der Elefant tanzte in der Disco und trug dabei eine Rassel um den Hals. Plötzlich fiel er durch die Decke und landete in einer Teetasse. Verwirrt trank er den Tee und verwandelte sich in einen Schmetterling.",
			question: "Wer will das Meer nicht verlassen?",
				recipient: undefined
		},
		{
			story: "Ein kleiner Finger namens Fred hatte genug davon, Teil der Hand seines Besitzers zu sein. Er rannte weg und fand sich im Paradies wieder, wo er sich in eine Ananas verliebte und beschloss, für immer dort zu bleiben.",
			question: "Was gewinnt der Kuchen?",
				recipient: undefined
		},
		{
			story: "Im Tiergarten lag eine Sphinx. Sie war halb Katze, halb Adler und sprach fließend Deutsch. Die Tiere verstanden sie nicht, aber sie versprach ihnen, dass sie eines Tages frei sein würden. Niemand glaubte ihr, bis eines Tages alle Tiere entkamen und die Sphinx verschwunden war.",
			question: "Wohin rollt die Münze?",
				recipient: undefined
		},
		{
			story: "In den Tiefen des Ozeans fand ein Selfie-Stick eine Nixe. Sie bat um ein Foto, doch die Nixe wollte das Meer nicht verlassen. Sie posierten gemeinsam, und das Selfie ging viral. Von nun an waren Selfie-Sticks die begehrtesten Accessoires der Unterwasserwelt.",
			question: "Was erobert der Meerrettich?",
				recipient: undefined
		},
		{
			story: "Kapitän Pirato, der Pirat ohne Führerschein, steuerte sein Schiff mit der Leidenschaft einer Rumba-Tänzerin. Seine Mannschaft war verwirrt, aber die Meeresbewohner jubelten. Als die Piraten auf einer einsamen Insel strandeten, verwandelten sie sich in Rumba tanzende Kokosnuss-Sammler.",
			question: "Wer verwandelt Milchshakes?",
				recipient: undefined
		},
		{
			story: "Der ungeduldige Scheibenwischer namens Swishy sehnte sich nach Urlaub. Er nahm so oft frei, dass sein Auto in einer Regenpfütze versank. Eines Tages erschien eine Blumenfee und bot ihm Blumen als Dank für seine Trockenheit an.",
			question: "Wer tanzt mit Kopfhörern?",
				recipient: undefined
		},
		{
			story: "Ein laktoseintoleranter Pfirsich namens Fuzzy fand sich auf einem Obelisken wieder. Er träumte davon, Milchshakes zu trinken, aber sein magischer Obelisk verwandelte alles in laktosefreie Alternativen. So wurde Fuzzy zum berühmtesten gesunden Frucht-DJ der Welt.",
			question: "Was tanzen die Kokusnuss-Sammler?",
				recipient: undefined
		}
	]
};
