import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import { config } from "./config";

export function getNavReturnElement(data) {
  const { src } = data;
  return (
    <Link to={"/"} draggable={false}>
      <Image className="nav return active" src={src} draggable={false} fluid />
    </Link>
  );
}

export function NavComponent() {
  const [counter, setCounter] = useState(0);

  const getNavElement = (data) => {
    const { src, link, enabled } = data;
    const className = "nav " + (enabled ? "active" : "inactive");
    const image = (
      <Image className={className} src={src} draggable={false} fluid />
    );
    return enabled ? (
      <Link to={link} draggable={false}>
        {image}
      </Link>
    ) : (
      image
    );
  };

  return (
    <Container className="Nav">
      <Row>
        <Col className="Col">{getNavElement(config.unicorn)}</Col>
        <Col className="Col">{getNavElement(config.squirrel)}</Col>
      </Row>
      <Row>
        <Col className="Col" onClick={() => setCounter(counter + 1)}>
          {getNavElement(config.bee)}
        </Col>
        <Col className="Col">
          {counter < 20
            ? getNavElement(config.turtle)
            : getNavElement({ ...config.turtle, enabled: true })}
        </Col>
      </Row>
    </Container>
  );
}
