import React from "react";
import { ParallaxPixelStars } from "./backgrounds/ParallaxPixelStars";
import { ViewportCenter } from "./components/ViewportCenter";
import { config } from "./config";
import { getNavReturnElement } from "./Nav";
import QRCode from "react-qr-code";
import { Card, Container, Row, Col } from "react-bootstrap";

function Turtle() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <ParallaxPixelStars />
      <ViewportCenter>
        {getNavReturnElement(config.turtle)}
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <Card>
                <Card.Body>
                  <QRCode
                    // value={"https://www.youtube.com/watch?v=G8XGYpdkYoA"} // follow me 
                    value={"https://www.youtube.com/watch?v=dQw4w9WgXcQ"} // rick roll
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </ViewportCenter>
    </div>
  );
}

export default Turtle;
