import React from "react";
import { useState } from "react";
import { ParallaxPixelStars } from "./backgrounds/ParallaxPixelStars";
import { ViewportCenter } from "./components/ViewportCenter";
import { config } from "./config";
import { getNavReturnElement } from "./Nav";
import QRCode from "react-qr-code";
import { Card, Container, Row, Col } from "react-bootstrap";

function Squirrel() {
  const [counter, setCounter] = useState(0);

  return (
    <div className="App" style={{ height: "100vh" }}>
      <ParallaxPixelStars />
      <ViewportCenter>
        {getNavReturnElement(config.squirrel)}
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <Card>
                <Card.Body>
                  <QRCode
                    value={
                      counter < 13
                        ? "==> 3x Under Construction <=="
                        : "Under ... Construction! Nothing To See Here"
                    }
                  />
                  <Card.Title
                    onClick={() => {
                      if (counter < 3) {
                        setCounter(counter + 1);
                      }
                    }}
                  >
                    Under Construction
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            {counter >= 3 && counter < 13 && (
              <Col>
                <Card>
                  <Card.Body>
                    <QRCode value={"Still Under Construction (10x)"} />
                    <Card.Title
                      onClick={() => {
                        setCounter(counter + 1);
                      }}
                    >
                      Still Under Construction
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </ViewportCenter>
    </div>
  );
}

export default Squirrel;
